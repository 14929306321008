import './styles.css';

import { validLanguages } from '@nx-smartmonkey/shared/domain';
import * as dateFnsLocales from 'date-fns/locale';
import { registerLocale } from 'react-datepicker';

(() => {
  for (const language of validLanguages) {
    let dateFnsLocalePackageName = language as string;
    if (language === `en`) {
      dateFnsLocalePackageName = `enUS`;
    }
    const locale = (dateFnsLocales as any)[dateFnsLocalePackageName];
    registerLocale(language as string, locale);
  }
})();
