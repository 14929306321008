import 'react-datepicker/dist/react-datepicker.css';

import { Language, LanguageType } from '@nx-smartmonkey/shared/domain';
import * as Popper from '@popperjs/core';
import { forwardRef, useRef } from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import { RoutalPalette } from '../Colors';
import { RoutalText as Text } from '../RoutalText';

interface StyledDatePickerInputProps {
  inputFocusBorderColor?: string;
}

const StyledDatePickerInput = styled.input`
  border-radius: 4px;
  border: 1px solid ${RoutalPalette.neutral20};
  outline: none;
  cursor: pointer;
  font-size: 13px;
  padding: 4px 8px;
  font-family: 'Geist Variable';
  min-height: 26px;
  display: block;
  width: calc(100% - 20px);
  margin-top: 4px;
  background-color: transparent;
  :focus {
    ${(props: StyledDatePickerInputProps) => `border-color: ${props.inputFocusBorderColor ?? RoutalPalette.primary00};`}
  }
  :hover {
    background-color: ${RoutalPalette.neutral05};
  }
`;

const DatePickerInput = forwardRef<HTMLInputElement, { inputFocusBorderColor?: string }>((props, forwardedRef) => (
  <StyledDatePickerInput type="text" ref={forwardedRef} {...props} />
));

export interface RoutalInputDatePickerProps {
  portalId?: string;
  dateValue?: Date | null;
  label?: string;
  required?: boolean;
  minDate?: Date;
  locale?: LanguageType;
  popperPlacement?: Popper.Placement | undefined;
  hasTime?: boolean;
  inputFocusBorderColor?: string;
  inline?: boolean;
  showMonthYearPicker?: boolean;
  onChange: (date: Date) => void;
  onClose?: () => void;
}

export const RoutalInputDatePicker = ({
  portalId,
  dateValue,
  label,
  required = false,
  minDate,
  locale = Language.getDefaultLanguage().value,
  popperPlacement,
  hasTime,
  inputFocusBorderColor,
  inline,
  showMonthYearPicker,
  onChange,
  onClose,
}: RoutalInputDatePickerProps) => {
  const customInputRef = useRef<HTMLInputElement>(null); // Ref needed for custom input

  const getDatePickerFormat = () => {
    switch (locale) {
      case `en`:
        return `MM/dd/yyyy ${hasTime ? `h:mm aa` : ``}`;
      default:
        return `dd/MM/yyyy ${hasTime ? `h:mm aa` : ``}`;
    }
  };

  return (
    <>
      {label ? (
        <Text tagName="span" style={{ marginBottom: `4px` }}>
          <>
            <Text tagName="span" variant="label">
              {label}
            </Text>
            {required ? (
              <Text tagName="span" variant="body" style={{ cursor: `default`, color: RoutalPalette.error.medium }}>
                *
              </Text>
            ) : null}
          </>
        </Text>
      ) : null}

      <DatePicker
        selected={dateValue}
        onChange={(date) => {
          if (date) {
            onChange(date);
          }
        }}
        minDate={minDate}
        locale={locale}
        customInput={<DatePickerInput inputFocusBorderColor={inputFocusBorderColor} ref={customInputRef} />}
        popperPlacement={popperPlacement}
        popperModifiers={[
          {
            name: `arrow`,
            options: {
              padding: ({ popper, reference, placement }) => ({
                right: Math.min(popper.width, reference.width) - 24,
              }),
            },
          },
        ]}
        portalId={portalId}
        dateFormat={getDatePickerFormat()}
        showMonthYearPicker={showMonthYearPicker}
        onClickOutside={onClose}
        inline={inline}
        {...(hasTime ? { showTimeInput: true, timeInputLabel: `Time:`, timeFormat: `h:mm aa` } : {})}
      />
    </>
  );
};
