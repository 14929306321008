import { ValueObject } from './ValueObject';

export const languageShort = (language: string) => language.split(/[-_]/)[0];

export type LanguageType = `en` | `es` | `pt` | `ca` | `de` | `hu` | `ro` | `fr` | `ru` | `fi`;

export const validLanguages: LanguageType[] = [`en`, `es`, `pt`, `ca`, `de`, `hu`, `ro`, `fr`, `ru`, `fi`];

export interface LanguageProps {
  value: LanguageType;
}

export class Language extends ValueObject<LanguageProps> {
  get value(): LanguageType {
    return this.props.value;
  }

  private constructor(props: LanguageProps) {
    super(props);
  }

  private static isValidLanguage(language: LanguageType) {
    return validLanguages.indexOf(language) !== -1;
  }

  static create(value: LanguageType): Language {
    if (!this.isValidLanguage(value)) {
      throw new Error(`Language ${value} is not valid`);
    } else {
      return new Language({ value });
    }
  }

  static getDefaultLanguage(): Language {
    return Language.create(`en`);
  }

  static fromNavigatorLanguage(navigatorLanguage: string) {
    const shortenLanguage = languageShort(navigatorLanguage);
    switch (shortenLanguage) {
      case `eu`: // Basque
      case `gl`: // Galician
        return Language.create(`es`);
      default:
        if (validLanguages.includes(shortenLanguage as LanguageType)) {
          return Language.create(shortenLanguage as LanguageType);
        }
        return Language.getDefaultLanguage();
    }
  }
}
