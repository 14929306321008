import { Language, LanguageType } from '@nx-smartmonkey/shared/domain';
import { TimeWindow } from '@nx-smartmonkey/shared/interfaces';
import moment from 'moment';

export const getWeekdayMonthAndYear = ({
  date,
  language = Language.getDefaultLanguage().value,
}: {
  date: Date;
  language?: LanguageType;
}): string => {
  const m = moment(date).locale(language);
  return m.format('LL');
};

export const getMonth = ({
  date,
  language = Language.getDefaultLanguage().value,
  large = false,
}: {
  date: Date;
  language?: LanguageType;
  large?: boolean;
}): string => {
  const m = moment(date).locale(language);
  return m.format(large ? 'MMMM' : 'MMM').replaceAll(`.`, ``);
};

export const getDay = ({
  date,
  language = Language.getDefaultLanguage().value,
}: {
  date: Date;
  language?: LanguageType;
}): string => {
  const m = moment(date).locale(language);
  return m.format(`DD`);
};

export const getYear = ({
  date,
  language = Language.getDefaultLanguage().value,
}: {
  date: Date;
  language?: LanguageType;
}): string => {
  const m = moment(date).locale(language);
  return m.format(`YYYY`);
};

export const getYearMonthDay = ({ date }: { date: Date }): string => {
  return moment(date).format(`YYYY-MM-DD`);
};

export const getMonthsLabel = ({
  language = Language.getDefaultLanguage().value,
  large = false,
}: {
  language?: LanguageType;
  large?: boolean;
}): string[] => {
  const m = moment().locale(language);
  const months = [];
  for (let i = 0; i < 12; i++) {
    const month = m.month(i).format(large ? 'MMMM' : 'MMM');
    const monthCapitalized = month.charAt(0).toUpperCase() + month.slice(1);
    months.push(monthCapitalized.replace(/\./g, ``));
  }
  return months;
};

export const getWeekdayLabel = ({
  language = Language.getDefaultLanguage().value,
}: {
  language?: LanguageType;
}): string[] => {
  const weekdays = [];
  const momentLocale = moment.localeData(language);
  const firstDayOfWeek = momentLocale.firstDayOfWeek();

  for (let i = firstDayOfWeek; i < firstDayOfWeek + 7; i++) {
    const dayName = momentLocale.weekdaysMin()[i % 7];
    const dayCapitalized = dayName.charAt(0).toUpperCase() + dayName.slice(1);
    weekdays.push(dayCapitalized.replace(/\./g, ``));
  }
  return weekdays;
};

export const toDayEnd = (date: Date) => {
  return new Date(date.setHours(23, 59, 59, 999));
};

export const toDayStart = (date: Date) => {
  return new Date(date.setHours(0, 0, 0, 0));
};

export const toDayNoon = (date: Date) => {
  return new Date(date.setHours(12, 0, 0, 0));
};

export const toMonthEnd = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59, 999);
};

export const toMonthStart = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0, 0);
};

export const addSeconds = (date: Date, seconds: number) => new Date(date.getTime() + seconds * 1000);

// Hours with decimals to hours and minutes
export const formatHours = (hours: number): string => {
  const hoursInt = Math.floor(hours);
  const minutesInt = Math.floor((hours - hoursInt) * 60);

  if (hoursInt < 0) {
    return ``;
  }

  const fullHours = hoursInt > 0 && hoursInt < 9 ? `0${hoursInt}` : hoursInt;
  const fullMinutes = minutesInt > 0 && minutesInt < 9 ? `0${minutesInt}` : minutesInt;
  return `${fullHours}h${fullMinutes}m`;
};

export const parseHMtoSeconds = (value: any) => {
  return value
    .split(`:`)
    .reduce((prev: any, next: any, idx: any) => prev + (idx === 0 ? next * 60 * 60 : next * 60), 0);
};

export const dateFormat = (value: any) => {
  return moment(new Date(value)).format(`DD/MM/YYYY HH:mm`);
};

export const dateFormatShortDate = (value: any) => {
  return moment(new Date(value)).format(`DD/MM/YY`);
};

export const dateFormatShortTime = (value: any) => {
  return moment(new Date(value)).format(`HH:mm`);
};

export const dateFormatLongDate = (value: any) => {
  return moment(new Date(value)).format(`DD/MM/YYYY`);
};

export const dateFormatToPicker = (value: any) => {
  return moment(value).format(`YYYY-MM-DD`);
};

export const dateTodayFormat = () => {
  return moment(new Date()).format(`DD/MM/YYYY HH:mm`);
};

export const dateTodayFormatExcel = () => {
  return moment(new Date()).format(`DD-MM-YYYY_HH'mm`);
};

export const dateTodayFormatPlan = () => {
  return moment(new Date()).format(`YYYY/MM/DD`);
};

export const parseSecondsToHM = (value: any, module24?: boolean): string => {
  if (value === undefined) return `--:--`;

  const totalSeconds = module24 ? value % (24 * 3600) : value;
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};
export const parseSecondsToExceededDays = (value: any): number | undefined => {
  if (value === undefined) return undefined;

  const days = Math.floor(value / (24 * 3600));

  return days;
};

export const parseTwoDatesToExceededDays = (date1: Date, date2: Date): number | undefined => {
  const diff = toDayStart(new Date(date2)).getTime() - toDayStart(new Date(date1)).getTime();
  const days = Math.floor(diff / (24 * 3600 * 1000));

  return days;
};

export const parseSecondsToString = (value: any) => {
  if (value === undefined) {
    return `0m`;
  }
  return `${`0${Math.floor(value / 3600)}`.slice(-2)}h ${`0${Math.floor(
    (value - Math.floor(value / 3600) * 3600) / 60
  )}`.slice(-2)}m`;
};

export const secondsToTime = (seconds: number) => {
  // The format should be HH:MM:SS
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const sec = seconds % 60;
  // each part should have 2 digits
  return `${`0${hours}`.slice(-2)}:${`0${minutes}`.slice(-2)}:${`0${sec}`.slice(-2)}`;
};

export const parseSecondsToHMText = (value: any) => {
  if (!value && parseInt(value, 10) !== 0) {
    return ``;
  }
  return `${Math.floor(value / 3600) > 0 ? `${Math.floor(value / 3600)}h ` : ``}${Math.floor(
    (value - Math.floor(value / 3600) * 3600) / 60
  )}m`;
};

export const parseSecondsToHMSText = (value: any) => {
  if (!value && parseInt(value, 10) !== 0) {
    return ``;
  }
  return `${Math.floor(value / 3600) > 0 ? `${Math.floor(value / 3600)}h ` : ``}${
    Math.floor(value - Math.floor(value / 3600) * 3600) / 60 > 0
      ? `${Math.floor((value - Math.floor(value / 3600) * 3600) / 60)}m `
      : ``
  }${Math.floor(value % 60)}s`;
};

export const twoDigits = (value: any) => {
  if (value < 10) {
    return `0${value}`;
  }
  return value;
};

export const millisecondsToMMss = (milliseconds: number) => {
  // force minutes to be a number between 0 and 59
  const minutes = Math.min(Math.max(Math.floor(milliseconds / 60000), 0), 59);
  const seconds = Math.min(Math.max(Math.floor((milliseconds % 60000) / 1000), 0), 59);
  return `${twoDigits(minutes)}:${twoDigits(seconds)}`;
};

// Seconds to minutes
export const secondsToMinutes = (seconds: any): number => {
  if (!seconds && parseInt(seconds, 10) !== 0) {
    return 0;
  }
  return Math.floor(seconds / 60);
};

export const timestampToHoursMinutes = (value: any) => {
  return `${twoDigits(new Date(value).getHours())}:${twoDigits(new Date(value).getMinutes())}`;
};

export const arrayTimeWindowsToString = (array: any) => {
  if (array && array.length > 0) {
    return array.map(
      (timeWindow: TimeWindow) => `${parseSecondsToHM(timeWindow[0])}-${parseSecondsToHM(timeWindow[1])} `
    );
  }
  return ``;
};

export const calculateTimeLeft = (date: any) => {
  // @ts-expect-error ts-migrate(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
  return Math.ceil((new Date(date) - new Date()) / (1000 * 60 * 60 * 24));
};

export const getAllDatesBetween = (array: any, field: any) => {
  const dates: any = [];
  if (array.length === 0) {
    return dates;
  }
  const currDate = moment(array[0].date);
  const lastDate = moment(array[array.length - 1].date);
  let i = 0;
  do {
    if (array[i].date === dateFormatToPicker(currDate.clone().toDate())) {
      dates.push(array[i]);
      i += 1;
    } else {
      dates.push({ date: dateFormatToPicker(currDate.clone().toDate()), [field]: 0 });
    }
  } while (currDate.add(1, `days`).diff(lastDate) <= 0);
  return dates;
};

export const pushZeroBetweenStartDateAndFirstDate = (array: any, startDate: any, field: any) => {
  if (array.length > 0) {
    const currDate = moment(array[0].date);
    const firstDate = moment(startDate);
    if (firstDate < currDate) {
      while (currDate.subtract(1, `days`).diff(firstDate) >= 0) {
        array.unshift({ date: dateFormatToPicker(currDate.clone().toDate()), [field]: 0 });
      }
    }
  }
  return array;
};

export const aggregateByWeek = (array: any, field: any) => {
  return array.reduce(
    (p: any, n: any) => ({
      ...p,
      [moment(n.date).week()]: p[moment(n.date).week()] ? p[moment(n.date).week()] + n[field] : 0 + n[field],
    }),
    {}
  );
};

export const getDiffTimeText = (diff: number, text?: string): string => {
  let newDiff = 0;
  let timeText = text ?? ``;

  // Days
  if (diff >= 3600 * 24) {
    const days = Math.floor(diff / (3600 * 24));
    timeText = `${days}d`;
    newDiff = diff - 3600 * 24 * days;
  } else if (diff >= 3600) {
    // Hours
    const hours = Math.floor(diff / 3600);
    timeText = `${timeText && timeText !== `` ? `${timeText} ` : ``}${hours}h`;
    newDiff = diff - 3600 * hours;
  } else if (diff > 60) {
    // Minutes
    const minutes = Math.floor(diff / 60);
    timeText = `${timeText && timeText !== `` ? `${timeText} ` : ``}${minutes}'`;
    return timeText;
  }

  if (newDiff >= 60) {
    return getDiffTimeText(newDiff, timeText);
  }
  return timeText;
};

export const calculateDuration = (duration: any) => (duration ? Math.ceil(duration / 60) : 0);

/**
 * calculateTimeLeft(`Thu Oct 28 2021 12:00:57 GMT+0200`) => 12:00
 * calculateTimeLeft(`Thu Oct 27 2021 12:00:57 GMT+0200`) => 27/10
 * calculateTimeLeft(`Thu Sep 28 2021 12:00:57 GMT+0200`) => 28/09
 * calculateTimeLeft(`Thu Sep 28 2020 12:00:57 GMT+0200`) => 28/09/20
 * @param date
 * @returns
 */
export const pastDateTime = (source: any) => {
  const now = moment(new Date());
  const date = moment(new Date(source));
  const diffYears = now.diff(date, `year`);

  if (diffYears !== 0) return date.format(`DD/MM/YY`);

  const diffDays = now.diff(date, `days`);
  if (diffDays !== 0) return date.format(`DD/MM`);

  return date.format(`HH:mm`);
};

// return verbose time elapsed since a date to now
// hora 12:32
// a partir d'ahir, només el dia i mes 18/07
// altre any, data sencera 18/07/1936
export const timeElapsed = (date: Date) => {
  const now = new Date();
  const diff = now.getTime() - date.getTime();
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((diff % (1000 * 60)) / 1000);
  if (days > 0) {
    return `${days}d`;
  }
  if (hours > 0) {
    return `${hours}h ${minutes}m`;
  }
  if (minutes > 0) {
    return `${minutes}m ${seconds}s`;
  }
  return `${seconds}s`;
};

export const roundHourWithThreshold = (seconds: number, threshold: number): number => {
  if (threshold === 0) {
    return seconds; // Return the input seconds as is when the threshold is zero
  }

  const minutes = seconds / 60;
  const roundedMinutes = Math.round(minutes / threshold) * threshold;
  const roundedSeconds = roundedMinutes * 60;
  return roundedSeconds;
};

export const getDistance = (point1: { lat: number; lng: number }, point2: { lat: number; lng: number }) => {
  const R = 6371e3; // Earth's radius in meters
  const lat1 = point1.lat * (Math.PI / 180);
  const lat2 = point2.lat * (Math.PI / 180);
  const deltaLat = (point2.lat - point1.lat) * (Math.PI / 180);
  const deltaLng = (point2.lng - point1.lng) * (Math.PI / 180);

  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.sin(deltaLng / 2) * Math.sin(deltaLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c; // Distance in meters
  return distance;
};
